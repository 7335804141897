import React, { useState } from 'react';
import { useSignUp } from '../hooks/useSignUp';

function SignUpComponent({ newsletterId, onSignUpSuccess }) {
    const { status, register } = useSignUp(newsletterId);
    const [email, setEmail] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);
    const privacyPolicyUrl = "https://privacy.wmg.com/wmi/privacy";
    const artistMktgConsentTxt = "By submitting my information I agree to the Official Rules and acknowledge that I have reviewed and agree to the Privacy Policy and Terms of Use; and I further agree to receive updates and marketing messages from Topsify.";

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!agreedToTerms) {
            setShowTermsError(true);
            return;
        }
        
        const adobeEcid = window.s?.visitor?.getMarketingCloudVisitorID?.() || '';
        const additionalParams = {
            privacy_policy_url: privacyPolicyUrl,
            artist_mktg_consent: true,
            artist_mktg_consent_txt: artistMktgConsentTxt,
            adobe_ecid: adobeEcid,
            datasource: 'WMPhilippines_TheMoonTeller_Standalone_Website',
            ref_url: encodeURIComponent(window.location.href)
        };
        register(email, additionalParams).then((success) => {
            if (success && onSignUpSuccess) {
                window._satellite?.track("email sign-up");
                onSignUpSuccess();
            }
        });
    };

    const handleTermsChange = (e) => {
        setAgreedToTerms(e.target.checked);
        if (e.target.checked) {
            setShowTermsError(false);
        }
    };

    return (
        <div className="p-6 bg-white rounded-lg">
            <p className="text-base text-gray-700 mb-4">
                Sign-up for updates from the Moon Teller Team and the latest on your music tastes
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <p className="text-sm text-gray-600 mb-2">
                    By submitting my information I agree to the <a href="https://wct.live/app/41604/theMoonTeller/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Official Rules</a> and acknowledge that I have reviewed and agree to the <a href="https://privacy.wmg.com/wmi/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Privacy Policy</a> and <a href="https://wct.live/app/41604/theMoonTeller/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Use</a>; and I further agree to receive updates and marketing messages from Topsify.
                </p>
                {showTermsError && (
                    <p className="text-red-600 font-bold text-sm">
                        Please agree to the Terms of Use and Privacy Policy
                    </p>
                )}
                <div className="flex items-start">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={agreedToTerms}
                        onChange={handleTermsChange}
                        className="mt-1 mr-2"
                    />
                    <label htmlFor="terms" className="text-sm text-gray-600">
                        I agree that I have read and accept the <a href="https://wminewmedia.com/terms-of-use/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Use</a> and Terms and Conditions.
                    </label>
                </div>
                <button
                    type="submit"
                    disabled={status === 'loading' || !agreedToTerms}
                    className={`w-full py-2 px-4 rounded-md text-white font-semibold ${
                        status === 'loading' || !agreedToTerms
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                >
                    {status === 'loading' ? 'Signing up...' : 'Sign Up'}
                </button>
            </form>
            {status === 'ready' && <p className="mt-4 text-green-600">Thank you for signing up!</p>}
            {status === 'error' && <p className="mt-4 text-red-600">An error occurred. Please try again.</p>}
        </div>
    );
}

export default SignUpComponent;
