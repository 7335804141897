import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Landing from './pages/Landing';
import About from './pages/About';
import Questions from './pages/Questions';
import BlurredGradient from './components/BlurredGradient';
import ProtectedRoute from './components/ProtectedRoute';

// Create a new component to handle navigation tracking
function NavigationTracker() {
    const location = useLocation();

    useEffect(() => {
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
    }, [location.pathname]);

    return null;
}

function App() {
    const [isSignedUp, setIsSignedUp] = useState(false);

    useEffect(() => {
        const userSignedUp = localStorage.getItem('userSignedUp');
        setIsSignedUp(userSignedUp === 'true');
    }, []);

    return (
        <div className="noise-overlay min-h-screen relative overflow-hidden bg-custom-bg">
            <BlurredGradient />
            <div className="relative z-10">
                <Router>
                    <NavigationTracker />
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/questions" element={
                            <ProtectedRoute isSignedUp={isSignedUp}>
                                <Questions />
                            </ProtectedRoute>
                        } />
                    </Routes>
                </Router>
            </div>
        </div>
    );
}

export default App;